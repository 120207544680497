import domReady from "@roots/sage/client/dom-ready";
import "./../../node_modules/flowbite/dist/flowbite.js";

/**
 * Application entrypoint
 */
domReady(async () => {
  initializePopupBanner();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);

function initializePopupBanner() {
  const COOKIE_NAME = "aflamil_popup_confirmation";
  const section = document.querySelector("#specialty-banner");
  const cookie = getCookie(COOKIE_NAME);
  const body = document.querySelector("body");
  const html = document.querySelector("html");

  // Display Banner
  if (cookie == "") {
    section.classList.remove("hidden");
    section.classList.add("flex");
    body.classList.add("stop-scrolling");

    const button = document.querySelector("#popup-confirmation");
    button.addEventListener("click", () => {
      // Remove banner and reanable scroll
      body.classList.remove("stop-scrolling");
      section.classList.remove("flex");
      section.classList.add("hidden");

      // set cookie
      setCookie(COOKIE_NAME, "true", 1);
    });
  }
}

// Cookie Helper Functions
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
